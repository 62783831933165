.fileuploader {
    cursor: pointer;
    margin-left: 10%;
    margin-right: 10%;
    height: auto;
    border: 2px dashed #bbb;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    font-family: Helvetica;
    font-size: 16px;
    color: #3d4852;
}

.fileuploader:hover {
    background-color: #f7f7f728;
    border: 2px dashed #000000;
}

.fileuploader_draging {
    background-color: #f8f8f87a;
}

.fileuploader_svg {
    margin: auto;
    display: block;
    height: 100px;
    height: 100px;
}

.fileuploader_text {
    display: block;
    font-weight: 600;
}

.fileuploader_or {
    margin-top: 5px;
    margin-bottom: 5px;
}

.fileuploader_button {
    border: none;
    background-color: #007aff;
    border-radius: 3px;
    color: #f5f5f5;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin: 0 auto;
    max-width: 250px;
    padding: 7px 12px;
    text-align: center;
}

.fileuploader_button:hover {
    background-color: #6fb5ff;
}

.fileuploader_progress {
    margin-top: 10px;
}

.fileuploader_resultContainer {
    margin-top: 5px;
}

.fileuploader_resultText {
    font-weight: 600;
}

.fileuploader_filenames {
    color: grey;
}

.fileuploader_filename {
    font-size: 12px;
}

.fileuploader_totalSize {
    margin-top: 5px;
    font-size: 12px;
    color: black;
}

.fileuploader_imageContainer {
    position: relative;
    display: inline-block;
}

.fileuploader_image {
    border-radius: 4px;
    width: 120px;
    height: 120px;
    margin: 3px;
}

.fileuploader_videoContainer {
    position: relative;
    display: inline-block;
}

.fileuploader_video {
    border-radius: 4px;
    width: 120px;
    height: 120px;
    margin: 3px;
}

.fileuploader_removeButton {
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: 2px;
    z-index: 100;
    padding: 5px;
    color: rgb(255, 0, 0)
}

.fileuploader_removeButton:hover {
    text-shadow: 2px 2px 5px white;
}

.fileuploader_errorContainer {
    margin-top: 10px;
}

.fileuploader_errorText {
    display: block;
    color: red;
    font-size: 14px;
}