.modal_overlay_large {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    z-index: 1000;
    animation: fadeIn 0.3s ease-out forwards;
}

.modal_content_large {
    background-color: white;
    padding: 0;
    border-radius: 5px;
    z-index: 1100;
    height: 100vh;
    animation: slideDown 0.3s ease-out forwards;
    display: flex;
    flex-direction: column;
}

.modal_overlay_small {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    animation: fadeIn 0.3s ease-out forwards;
}

.modal_content_small {
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    z-index: 1100;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    animation: slideDown 0.4s ease-out forwards;
}

.modal_content_small::-webkit-scrollbar {
    width: 8px;
}

.modal_content_small::-webkit-scrollbar-track {
    background: rgba(240, 240, 240, 0.7);
    border-radius: 10px;
}

.modal_content_small::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.4);
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.8);
}

.modal_content_small::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.6);
}

.modal_overlay_mediagallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal_content_mediagallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.modal_closebutton {
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 20px;
    transition: transform 0.3s ease, filter 0.3s ease;
    z-index: 1200;
}

.modal_closebuttonimage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.modal_closebutton:hover {
    transform: scale(1.1);
    filter: brightness(85%);
}

body.modal_removeoverflow {
    overflow: hidden;
}

.modal_buttonscontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal_button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
}

.modal_button:hover {
    background-color: #0056b3;
}

.modal_button:active {
    background-color: #004494;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}