.filtersbar {
    margin-top: 10px;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 0;
    text-align: center;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(198, 198, 198);
    height: 40px;
}

.filtersbar_filter {
    margin: 5px;
    display: inline-flex;
}

.filtersbar_select {
    height: 30px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.filtersbar_option {
    font-size: 16px;
}

@media (max-width: 600px) {
    .filtersbar {
        margin-top: 5px;
        height: 27px;
    }

    .filtersbar_filter {
        margin: 3px;
    }

    .filtersbar_select {
        height: 25px;
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .filtersbar {
        margin-top: 3px;
        height: 25px;
    }

    .filtersbar_filter {
        margin: 3px;
    }

    .filtersbar_select {
        height: 23px;
        font-size: 10px;
    }
}