.authscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.authscreen_container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.authscreen_title {
    font-size: 24px;
    margin-bottom: 20px;
}

.authscreen_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
}

.authscreen_rowsection {
    flex: 1;
}

.authscreen_label {
    margin-top: 5px;
    margin-bottom: 5px;
}

.authscreen_passwordinput {
    flex: 5;
}

.authscreen_passwordicon {
    flex: 1
}

.authscreen_input {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(153, 153, 153);
    color: #7e7e7e;
    padding: 12px 15px;
    text-decoration: none;
    font-size: 16px;
    margin: 10px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 8px;
}

.authscreen_showhidebutton {
    background: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.authscreen_showhidebuttonicon {
    width: 30px;
    height: 30px;
}

.authscreen_resultcontainer {
    margin-top: 5px;
}

.authscreen_result {
    color: red;
}

.authscreen_buttonscontainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.authscreen_button {
    width: 120px;
    height: 40px;
    background-color: #ffc552;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.authscreen_backbutton {
    display: block;
}

.authscreen_backbuttonicon {
    cursor: pointer;
    width: 50px;
    height: 50px;
}

@media (max-width: 600px) {
    .authscreen_container {
        width: 80%;
    }

    .authscreen_button {
        width: auto;
    }
}

@media (max-width: 400px) {
    .authscreen_container {
        width: 80%;
    }

    .authscreen_button {
        width: auto;
    }
}