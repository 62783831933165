.soukakaratai {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.soukakaratai_title {
    background-color: #3498db;
    color: #fff;
    padding: 15px;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.soukakaratai_chats {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.soukakaratai_chatuser,
.soukakaratai_chatai {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 10px;
    max-width: 70%;
}

.soukakaratai_chatuser {
    background-color: #ecf0f1;
    text-align: right;
    margin-left: auto;
}

.soukakaratai_chatai {
    background-color: #bae3ff;
    text-align: left;
    margin-right: auto;
}

.soukakaratai_inputgroup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    height: 15%;
    display: flex;
}

.soukakaratai_input {
    flex: 5;
    padding: 15px;
    border: 1px solid #ccc;
    resize: none;
    font-size: 1.2em;
}

.soukakaratai_inputbutton {
    flex: 1;
    background-color: #3498db;
    color: #fff;
    padding: 15px 25px;
    margin: 0px 5px 0px 5px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s;
}

.soukakaratai_inputbutton:hover {
    background-color: #2980b9;
}