.users {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.users_users {
    margin-top: 5px;
    margin-bottom: 10px;
}

.users_input {
    width: 80%;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.users_input::placeholder {
    color: #999;
}

.users_input:focus {
    outline: none;
    border-color: #5c9eff;
    box-shadow: 0 0 5px #5c9eff;
}

.users_table {
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #333;
}

.users_editButton {
    display: inline-block;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.users_editButton:hover {
    background-color: #3e8e41;
}

.userlist_spinnerContainer {
    margin: auto;
    text-align: -webkit-center;
}

.users_loadmorebuttoncontainer{
    text-align: center;
}

.users_loadmorebutton{
    display: inline-block;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}