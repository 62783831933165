.menueditor {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.menueditor_table {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
}

.menueditor_districtitem {
    border: 1px solid #ddd;
    align-items: center;
    background-color: rgb(227 227 227);
}

.menueditor_districtitem td {
    padding: 15px;
}

.menueditor_communityitem {
    border: 1px solid #ddd;
    align-items: center;
}

.menueditor_communityitem td {
    padding: 15px;
}

.menueditor_openbutton {
    cursor: pointer;
}

.menueditor_openbutton:hover {
    background-color: rgba(197, 197, 197, 0.342);
}

.menueditor_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.menueditor_modalcontent {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    display: flex;
}

.menueditor_modaltitle {
    font-size: 18px;
    margin-bottom: 20px;
}

.menueditor_modalinput {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.menueditor_modalbutton {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.menueditor_modalbutton:hover {
    background-color: #d32f2f;
}