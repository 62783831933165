.socialmediaeditor {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.socialmediaeditor_buttonscontainer {
    margin-top: 10px;
}

.socialmediaeditor_button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.socialmediaeditor_select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.socialmediaeditor_table {
    width: 100%;
    border-collapse: collapse;
}

.socialmediaeditor_table th,
.socialmediaeditor_table td {
    padding: 8px;
}

.socialmediaeditor_tablelink {
    background-color: #e7f3fe;
    font-weight: bold;
}

.socialmediaeditor_tablecommunity {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
}

.socialmediaeditor_tablecommunity:hover {
    background-color: #fef1d7;
}

.socialmediaeditor_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1200;
}

.socialmediaeditor_modalcontent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.socialmediaeditor_modaltitle {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.socialmediaeditor_modalselect {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}