.title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3498db;
    color: #fff;
    padding: 18px;
    font-size: 1.5em;
}

.tite_picturecontainer {
    flex-shrink: 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.tite_picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.titletext {
    font-size: 24px;
    color: #ffffff;
    margin: 0;
    font-weight: bold;
}