.bottomnavigator {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #7c7c7cdb;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    height: 40px;
    text-align: center;
}

.bottomnavigator_section {
    flex: 1;
    border-radius: 6px;
    transition: background-color 0.3s;
}

.bottomnavigator_section:hover {
    background-color: rgb(177, 177, 177);
}

.bottomnavigator_button {
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    transition: transform 0.3s ease;
    cursor: pointer;
    color: white;
}

.bottomnavigator_button:hover {
    transform: translateY(-2px);
}

.bottomnavigator_icon {
    height: 30px;
    transition: transform 0.3s ease;
}

.bottomnavigator_icon:hover {
    transform: scale(1.1);
}

@media (max-width: 600px) {
    .bottomnavigator {
        height: 30px;
    }

    .bottomnavigator_button {
        padding: 6px 3px;
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .bottomnavigator {
        height: 25px;
    }

    .bottomnavigator_button {
        padding: 8px 2px;
        font-size: 12px;
    }
}