.nomatch {
    background-color: #fafff8;
    height: 100%;
    width: 100%;
    text-align: center;
}

.nomatch__title {
    font-size: 14em;
    padding: 10px;
    font-family: "Calibri";
    font-weight: 100;
}

.nomatch__caption {
    font-weight: bold;
    padding: 5px;

}

.nomatch__text {
    font-weight: 100;
    text-transform: uppercase;
    padding: 5px;
}

.nomatch__property{
    display: block;
}

.nomatch__homebutton {
    padding: 10px;
    cursor: pointer;
    margin: 10px;
    border: 0;
    background-color: black;
    color: white;
    height: 50px;
}