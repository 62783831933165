.notifications {
    text-align: center;
}

.notifications_notification {
    cursor: pointer;
    padding: 30px;
    font-size: 20px;
}

.notifications_notification:hover {
    background-color: rgb(238, 238, 238);
}

.notifications_highlighted{
    font-weight: bold;
}

.notifications_notificationtext{
    font-size: 20px;
}

.notifications_notificationago{
    font-weight: 700;
    font-size: 16px;
}