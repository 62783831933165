.annoucements {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.annoucements .annoucements_textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-size: 16px;
}

.annoucements .annoucements_result {
    margin-top: 10px;
    color: #3498db;
    font-weight: bold;
}

.annoucements .annoucements_savebutton {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
}

.annoucements .annoucements_savebutton:hover {
    background-color: #2676b9;
}