.countries {
    text-align: center;
}

.countries_section1 {
    height: 40px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.countries_section1text1 {
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: 'Jockey One', sans-serif;
}

.countries_section1text2 {
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: 'Changa', sans-serif;
}

.countries_section2 {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.countries_section2logo {
    width: 500px;
    height: 150px;
    object-fit: contain;
    align-self: flex-start;
}

.countries_section2title {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.countries_section2title1 {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Jockey One', sans-serif;
    margin-bottom: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.countries_section2title2 {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Almarai', sans-serif;
    color: red;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.countries_section3 {
    display: block;
    margin-bottom: 40px;
    border-bottom: 1px solid darkgray;
}

.countries_section3countrycontainer {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.countries_section3countryitem {
    cursor: pointer;
    line-height: 1.3;
    margin: 10px;
    text-decoration: none;
}

.countries_section3countryitemtext {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1;
    color: #1a0dab;
    -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
    text-transform: uppercase;
}

.countries_section3countryitemtext:hover {
    text-decoration: underline;
}

.countries_section4 {
    display: block;
    margin-bottom: 40px;
    border-bottom: 1px solid darkgray;
    padding: 0 5px 0 5px;
}

.countries_section4title {
    font-weight: 400;
    color: #FF7E00;
    text-transform: uppercase;
    text-align: left;
    margin-left: 15px;
}

.countries_section4text {
    font-size: 20px;
    font-weight: 200;
    text-align: left;
    color: #4D5060;
    padding: 10px;
}

.countries_section4hashtags {
    font-size: 20px;
    font-weight: 400;
}

.countries_section5 {
    display: block;
    margin-bottom: 40px;
    border-bottom: 1px solid darkgray;
    padding: 0 5px 0 5px;
}

.countries_section5title {
    font-weight: 400;
    color: #FF7E00;
    text-transform: uppercase;
    text-align: left;
    margin-left: 15px;
}

.countries_section5text {
    font-size: 20px;
    font-weight: 200;
    text-align: left;
    color: #4D5060;
    padding: 10px;
}

.countries_section5textarabic {
    font-size: 20px;
    font-weight: 200;
    text-align: left;
    color: #4D5060;
    padding: 10px;
    direction: rtl;
    text-align: right;
}

.countries_footer {
    width: 100%;
    background-color: black;
    color: white;
    display: flex;
}

.countries_footercol {
    flex: 1;
}

.countries_footerLinkContainer {
    display: block;
}

.countries_footerLink {
    color: white;
    text-decoration: none;
}

.countries_footerLink:hover {
    text-decoration: underline;
}

@media (max-width: 870px) {
    .countries_section1text1 {
        font-size: 16px;
    }

    .countries_section1text2 {
        font-size: 16px;
    }

    .countries_section2logo {
        width: 500px;
        height: 130px;
    }
}

@media (max-width: 770px) {
    .countries_section2logo {
        display: none;
    }

    .countries_section2title {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .countries_section2title1 {
        font-size: 16px;
    }

    .countries_section2title2 {
        font-size: 16px;
    }

    .countries_section3countryitemtext {
        font-size: 16px;
        margin: 4px;
        line-height: 1;
    }
}

@media (max-width: 400px) {
    .countries_section1text2 {
        display: none;
    }

    .countries_headertext {
        font-size: 10px;
    }
}