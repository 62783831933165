.profile {
    display: block;
    text-align: center;
}

.profile_container{
    padding: 10px;
}

.profile_username {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.profile_userprofilepicture {
    margin-top: 10px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profile_userbiocontainer {
    width: 100%;
    margin-top: 20px;
}

.profile_userbio {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.profile_chatbuttoncontainer {
    margin-top: 10px;
}

.profile_chatbutton {
    margin: auto;
    width: 70%;
    background-color: #7c7c7c;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile_chatbutton:hover {
    background-color: #a1a1a1;
}