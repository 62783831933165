.addlisting {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.addlisting_credits {
    margin-top: 10px;
}

.addlisting_textarea {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: auto;
    margin-top: 20px;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 70%;
    font-size: 18px;
    height: auto;
}

.addlisting_smartsearch {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 10px auto;
    padding: 0.375rem 0.75rem;
    width: 40%;
    font-size: 18px;
}

.addlisting_smartsearch input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: inherit;
}

.addlisting_smartsearchclearbutton {
    cursor: pointer;
}

.addlisting_smartsearchclearicon {
    width: 20px;
    height: 20px;
}

.addlisting_smartsearchclearicon:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.addlisting_smartsearchspinner {
    margin-left: 10px;
}

.addlisting_summary {
    margin: 20px 0px 20px 0px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

.addlisting_summarykey {
    display: inline;
    margin-left: 10px;
    border-radius: 3px;
}

.addlisting_summaryvalue {
    display: inline;
    margin: 0px 3px 0px 0px;
}

.addlisting_select {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: 10px auto 10px;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
    font-size: 20px;
}

.addlisting_morelessbutton {
    font-size: 14px;
    line-height: 1.8;
    background-color: #ff0f0f;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    padding: 0.375rem 0.75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.addlisting_resultcontainer {
    color: red;
}

.addlisting_spinnercontainer {
    margin: auto;
    width: 40px;
    height: 40px;
}

.addlisting_smartsearchcontainer {
    position: relative;
    margin: 10px auto;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 60%;
}

.addlisting_smartsearchloadingcontainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.addlisting_smartsearchitem {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.addlisting_smartsearchitem:nth-child(even) {
    background-color: #f8f8f8;
}

.addlisting_smartsearchitem:hover {
    background-color: #f0f0f0;
}

.addlisting_smartsearchitem:last-child {
    border-bottom: none;
}

.addlisting_submitbutton {
    background-color: #007bff;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    line-height: 2.5;
    margin: 5px;
    padding: 0.375rem 0.75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 40%;
}