.maintenance {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.maintenance_section {
    margin-bottom: 20px;
    background-color: #fff;
}

.maintenance_sectiontitle {
    font-size: 18px;
    font-weight: bold;
}

.maintenance_sectiondescription{
    color: red;
}

.maintenance_buttons {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.maintenance_buttoncontainer {
    flex: 1;
}

.maintenance_getbutton {
    background-color: #4caf50;
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.maintenance_getbutton:hover {
    background-color: #c9c9c9;
}

.maintenance_fixbutton {
    background-color: #2196f3;
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.maintenance_fixbutton:hover {
    background-color: #c9c9c9;
}

.maintenance_table {
    padding: 10px;
    width: 100%;
}

.maintenance_result {
    font-size: 14px;
    overflow-wrap: anywhere;
}