.listing {
    display: block;
    text-align: center;
}

.listing_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3498db;
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding: 18px;
}

.listing_closebuttoncontainer {
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 20px;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.listing_closebutton {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.listing_headertitle {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin: auto;
}

.listing_info {
    margin-top: 30px;
}

.listing_infotitle {
    margin-top: 20px;
    padding-top: 15px;
    padding-right: 10%;
    padding-left: 10%;
    font-size: x-large;
    font-weight: 500;
}

.listing_infoauthor {
    display: inline;
    font-size: 0.8em;
    text-align: left;
    font-weight: bold;
    cursor: pointer;
}

.listing_datetime {
    font-size: 14px;
    color: rgb(97, 97, 97)
}

.listing_infoseperator {
    font-size: 0.8em;
    color: grey;
    display: inline;
}

.listing_infocol1,
.listing_infocol2,
.listing_infocol3,
.listing_infocol4 {
    display: block;
}

.listing_infocol2 {
    margin-top: 20px;
    padding-right: 5%;
    padding-left: 5%;
}

.listing_description {
    word-wrap: break-word;
    border: 1px solid rgb(174, 174, 174);
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 2%;
    padding-right: 2%;
}

.listing_line::selection {
    background: #3dd44f;
}

.listing_seephonenumbers {
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    padding: 0.35em 1.2em;
    border: 1px solid #980e0e;
    border-radius: 5px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    transition: all 0.2s;
}

.listing_seephonenumbers:hover {
    color: #3dd44f;
    background-color: #d3d3d3;
    border: 1px solid #7e7e7e;
}

.listing_tablecontainer {
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 10px;
}

.listing_table {
    border-radius: 5px;
    font-weight: normal;
    border: 1px solid black;
    width: 100%;
    max-width: 100%;
    background-color: white;
}

.listing_table tr:nth-child(even) {
    background: #dddddd;
}

.listing_images {
    display: block;
}

.listing_imagecontainer {}

.listing_image {
    cursor: pointer;
    max-width: 100%;
}

.listing_optionssection {
    margin-top: 10px;
}

.listing_comments {
    padding-right: 15%;
    padding-left: 15%;
    margin-top: 15px;
}

.listing_spinnercontainer {
    width: 25%;
    height: 25%;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 720px) {
    .listing_description {
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 10px;
        padding-bottom: 15px;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media screen and (max-width: 550px) {
    .listing_description {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 15px;
        padding-left: 5%;
        padding-right: 5%;
    }
}