.listings {
    display: block;
}

.listings::-webkit-scrollbar {
    width: 10px;
}

.listings::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.listings::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.listings::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.listings_content {
    padding-bottom: 80px;
}

/****************************************************************************************/

.listings_listing {
    background-color: white;
    padding: 3px;
    height: auto;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 2px;
    margin: auto;
    margin-top: 5px;
    margin-right: 30%;
    margin-left: 30%;
}


.listings_listingsnew {
    background: #efefef;
}

/****************************************************************************************/

.listings_textalignleft {
    text-align: left;
}

.listings_textalignright {
    text-align: right;
}

.listings_textdirectionalityright {
    direction: rtl;
}

/****************************************************************************************/

.listings_listingseemore {
    font-weight: 600;
    cursor: pointer;
    color: #333;
    text-align: center;
}

.listings_listingseemore:hover {
    font-weight: 700;
}

/****************************************************************************************/

.listings_listinginfo {
    display: flex;
    flex-direction: row;
    margin: 0% 3% 4% 3%;
    text-align: center;
    padding: 3px;
    font-size: 12px;
    border-bottom: 1px solid rgba(224, 224, 224, 0.63);
}

.listings_seperator {
    color: grey;
    display: inline;
}

.listings_lister {
    display: inline;
    font-weight: 600;
    color: #252525;
    cursor: pointer;
}

.listings_timestamp {
    display: inline;
    color: grey;
}

.listings_district {
    display: inline;
    color: grey;
}

.listings_community {
    display: inline;
    color: grey;
}

.listings_agreement {
    display: inline;
    color: grey;
}

.listings_type {
    display: inline;
    color: grey;
}

.listings_views {
    display: inline;
    color: grey;
    font-weight: 500;
}

.listings_newflag {
    margin-left: 3px;
    color: red;
    font-weight: 700;
}

.listings_listinginfoleft,
.listings_listinginforight {
    flex: 1;
}

.listings_listinginfocenter {
    flex: 5;
}

.listings_listinginfocentercontents {
    margin-top: 3px;
}

.listings_listinginforightoptionsbutton {
    display: inline-block;
    background-color: #ffffff00;
    color: #333;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 24px;
    transition: background-color 0.3s ease;
}

.listings_listinginforightoptionsbutton:hover {
    background-color: #e0e0e0;
}

.listings_listinginforightoptionsbutton:active {
    background-color: #ccc;
}

/****************************************************************************************/

.listings_listingdescription {
    text-decoration: none;
    color: rgb(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 14px;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
    border-radius: 3px;
    overflow: hidden;
    transition: background-color 0.3s ease;
}

.listings_descriptionshowmore {
    font-weight: 600;
    cursor: pointer;
    color: rgb(0, 0, 50);
}

.listings_descriptionshowmore:hover {
    text-decoration: underline;
}

/****************************************************************************************/

.listings_listingimages {
    margin-top: 4px;
    text-align: center;
    display: block;
}

.listings_listingimage {
    object-fit: cover;
    object-position: center center;
    height: 200px;
    max-height: 200px;
    padding: 0px 2px 2px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
}

.listings_listingimage:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/****************************************************************************************/

.listings_showmore {
    width: 80%;
    margin: auto;
    margin-top: 15px;
    border-radius: 3px;
    margin-bottom: 15px;
    height: 120px;
    cursor: pointer;
}

.listings_showmore:hover {
    background-color: rgba(197, 197, 197, 0.616);
    border: 1px solid rgb(177, 177, 177);
}

.listings_showmoretext {
    color: black;
    padding-top: 50px;
    font-weight: 100;
}

.listings_spinnercontainer {
    margin-top: 20px;
}

/****************************************************************************************/

.listings_seemorecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;
}

.listings_seemore {
    text-align: center;
    cursor: pointer;
    border: 1px solid rgb(198, 198, 198);
    background-color: rgb(209, 209, 209);
    height: 100px;
    width: 80%;
    line-height: 50px;
    transition: background-color 0.3s, color 0.3s;
}

.listings_seemore:hover,
.listings_seemore:focus {
    background-color: #666;
    color: white;
    border-color: #666;
}

.listings_seemore:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #777;
    border-color: #ccc;
}

/****************************************************************************************/

.listings_nolistings {
    text-align: center;
    margin-top: 10px;
}

.listings_nolistingstitle {
    font-size: 1.4em;
    text-transform: uppercase;
    color: red
}

.listings_nolistingsmessage {
    display: block;
    font-size: 1.0em;
    font-weight: 100;
    text-transform: uppercase;
}

/****************************************************************************************/

@media (max-width: 1200px) {
    .listings_listing {
        margin-right: 20%;
        margin-left: 20%;
    }
}

@media (max-width: 1000px) {
    .listings {
        margin-bottom: 50px;
    }

    .listings_listing {
        margin-right: 10%;
        margin-left: 10%;
    }

    .listings_seemore {
        margin-right: 10%;
        margin-left: 10%;
    }
}

@media (max-width: 550px) {
    .listings {
        margin-bottom: 40px;
    }

    .listings_listing {
        margin-right: 0%;
        margin-left: 0%;
    }
}