.chats {
    display: block;
}

.chats_searchinputgroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 20px auto;
}

.chats_searchinput {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.chats_searchinput:focus {
    border-color: #007bff;
}

/*-----------------------------------------*/

.chats_panel {
    display: flex;
    flex-direction: column;
}

.chats_panel::-webkit-scrollbar {
    width: 10px;
}

.chats_panel::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chats_panel::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.chats_panel::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.chats_chatcontent {
    flex: 9;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}

.chats_chatcontent:hover {
    background-color: #e2e2e2;
}

.chats_optionscontainer {
    flex: 1;
    display: grid;
    cursor: pointer;
    align-items: center;
    border-radius: 5px;
}

.chats_optionscontainer:hover {
    background-color: #e2e2e2;
}

.chats_chat {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.chats_userprofilepicturecontainer {
    flex: 1;
    margin-right: 10px;
}

.chats_userprofilepicture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.chats_usernamecontainer {
    flex: 5;
}

.chats_username {
    font-size: 18px;
    font-weight: bold;
}

.chats_usernamechatread {
    color: darkgray;
    margin-top: 5px;
}

.chats_usernamechatunread {
    color: black;
    font-weight: 600;
    margin-top: 5px;
}

.chats_fromnow {
    color: darkgray;
    flex: 1;
    text-align: right;
    font-size: 12px;
}

.chats_option {
    display: block;
}

.chats_nochat {
    font-size: 16px;
    color: #555;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
}

/*-----------------------------------------*/

.chats_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chats_modalcontent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.chats_modalremove,
.chats_modalcancel {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.chats_modalremove:hover,
.chats_modalcancel:hover {
    background-color: #f0f0f0;
    color: #333;
}

.chats_modalremove {
    color: #ff0000;
    font-weight: 600;
}

.chats_modalcancel {
    color: #000000;
}