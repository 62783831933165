.settings {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.settings_row {
    display: block;
}

.settings_rowsection {
    padding: 10px 10px 0px 10px;
}

.settings_removemyprofilepicturecontainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.settings_removemyprofilepicturebutton {
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.settings_removemyprofilepicturebutton:hover {
    background-color: #2980b9;
}

.settings_profilepicture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.settings_noprofilepicture {
    font-size: 18px;
    color: #888;
}

.settings_uploadbutton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings_uploadbuttoninput {}


.settings_label{
    font-weight: 700;
}

.settings_input {
    padding: 5px 8px 0 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
}

.settings_textarea {
    padding: 5px 12px 0 12px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
    resize: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.settings_result {
    margin-top: 10px;
    font-size: 18px;
    color: green;
    font-weight: 500;
}

.settings_savebutton,
.settings_changepasswordbutton {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings_changepasswordbutton {
    background-color: #dc3545;
}

.settings_removeprofilecontainer {
    text-align: left;
}

.settings_removeprofilebutton {
    font-weight: 600;
    color: #dc3545;
    cursor: pointer;
}

.settings_removeprofilebutton:hover {
    color: #ff6170;
}


.settings_removeprofilemodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings_removeprofilemodaltitle {
    margin-bottom: 20px;
    font-size: 24px;
}

.settings_removeprofilemodalbutton {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 500px) {

    .settings_container {
        margin: 0;
    }

    .settings_input {
        font-size: 18px;
    }

    .settings_textarea {
        font-size: 20px;
    }
}