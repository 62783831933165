.countryeditor {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.countryeditor .countryeditor_table {
    width: 100%;
    border-collapse: collapse;
}

.countryeditor .countryeditor_table th,
.countryeditor .countryeditor_table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.countryeditor .countryeditor_table th {
    background-color: #f2f2f2;
}

.countryeditor .countryeditor_tabletogglebutton {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.countryeditor_tabletoggleoptionsbutton {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: #ffffff;
    border: 2px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.countryeditor_tabletoggleoptionsbutton:hover {
    background-color: #45a049;
    color: #ffffff;
}

.countryeditor .countryoptions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    z-index: 999;
}

.countryeditor_modal {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.countryeditor_modaltitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.countryeditor_modaltitleinputlabel {
    font-weight: bold;
    margin-top: 10px;
}

.countryeditor_modaltitleinput {
    width: calc(100% - 16px);
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}

.countryeditor_modalresult {
    margin-top: 10px;
    color: red;
}

.countryeditor_modalbutton {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.countryeditor_modalbutton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}