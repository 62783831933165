.useredit {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.adminuseredit_profilepicwrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
}

.adminuseredit_profilepic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.adminuseredit_userid {
    font-weight: bold;
}

.adminuseredit_username,
.adminuseredit_useremail {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
}

.adminuseredit_userbiography {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    resize: none;
}

.adminuseredit_usertype,
.adminuseredit_userstatus {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
}

.adminuseredit_result {
    margin-bottom: 12px;
}

.adminuseredit_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.adminuseredit_buttonssave {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.adminuseredit_buttonssave:hover {
    background-color: #45a049;
}

.adminuseredit_buttonsdelete {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.adminuseredit_buttonsdelete:hover {
    background-color: #d32f2f;
}

.adminuseredit_confirmationmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.adminuseredit_confirmationmodaloverlay {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminuseredit_confirmationmodaltitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.adminuseredit_confirmationmodalyesbutton,
.adminuseredit_confirmationmodalnobutton {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.adminuseredit_confirmationmodalyesbutton {
    background-color: #f44336;
    color: white;
}

.adminuseredit_confirmationmodalnobutton {
    background-color: #e0e0e0;
}