.chat {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}

.chat_container {
    display: block;
}

.chat_panel {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: fixed;
    width: 100%;
}

.chat_panel::-webkit-scrollbar {
    width: 10px;
}

.chat_panel::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chat_panel::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.chat_panel::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.chat_emptycontainer {
    margin: auto;
}

.chat_emptytext {
    font-size: 24px;
    font-weight: 400;
}

.chat_date {
    margin: 8px auto 5px auto;
    font-weight: 600;
}

.chat_fromme {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    cursor: pointer;
    align-self: flex-end;
    background-color: #3498db;
    padding: 8px;
    border-radius: 8px;
    margin-right: 5px;
    font-weight: 500;
}

.chat_fromme:hover {
    background-color: #88cfff;
}

.chat_fromyou {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    cursor: pointer;
    align-self: flex-start;
    background-color: #d5d5d5;
    padding: 8px;
    border-radius: 8px;
    margin-left: 5px;
    font-weight: 500;
}

.chat_fromyou:hover {
    background-color: #9a9a9a;
}

.chat_frommeoptions {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #3498db;
}

.chat_fromyouoptions {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #d5d5d5;
}

.chat_leftoption {
    flex: 5;
}

.chat_time {
    font-size: 10px;
}

.chat_rightoption {
    flex: 1;
}

.chat_optionbutton {
    padding: 3px 5px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat_optionbutton:hover {
    background-color: #a5a5a5;
}

.chat_chat {
    font-size: 16px;
    white-space: pre-wrap;
}

.chat_laststatus {
    height: 1px;
    background-color: #aeaeae;
    margin: 10px auto;
    width: 50%;
}

/*-----------------------------------------*/

.chat_inputcontainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    height: 15%;
}

.chat_errorcontainer {
    margin-bottom: 10px;
    text-align: center;
}

.chat_errortext {
    color: red;
    font-weight: bold;
    font-size: 14px;
}

.chat_inputgroup {
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
}

.chat_inputtextarea {
    flex: 1;
    height: 50px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    font-family: Arial, sans-serif;
}

.chat_inputtextareartl {
    direction: rtl;
}

.chat_inputtextarealtr {
    direction: ltr;
}

.chat_sendmessagecontainer {
    margin-left: 10px;
}

.chat_sendmessagebutton {
    background-color: #0084ff;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat_sendmessagebutton:hover {
    background-color: #0056b3;
}

.chat_sendmessageicon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}


/*-----------------------------------------*/

.chat_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.chat_modalcontent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.chat_modalremove,
.chat_modalcancel {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.chat_modalremove:hover,
.chat_modalcancel:hover {
    background-color: #f0f0f0;
    color: #333;
}

.chat_modalremove {
    color: #ff0000;
    font-weight: 600;
}

.chat_modalcancel {
    color: #000000;
}

/*-----------------------------------------*/

@media (max-width: 600px) {
    .chat_date {
        margin: 8px auto 10px auto;
    }
}