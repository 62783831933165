.communities {
    display: block;
    text-align: center;
}

.communities_search {
    padding: 12px 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.communities_search:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.3);
}

.communities_community {
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
}

.communities_community:hover {
    background-color: #f0f0f0;
}

.communities_communityselected {
    background-color: #5c5c5c;
}