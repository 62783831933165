.header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-bottom: 1px solid grey;
}

.header_bar {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96%
}

.header_item {
    cursor: pointer;
    flex: 1;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.header_item:hover {
    background-color: rgba(214, 214, 214, 0.743);
}

.header_logo {
    width: 120px;
    object-fit: contain;
    height: 50px;
    border-radius: 10px;
}

.header_icon {
    height: 50px;
    width: 50px;
    transition: transform 0.3s ease;
}

.header_icon:hover {
    transform: scale(1.1);
}

.header_notificationscount {
    color: red;
    font-size: 14px;
    font-weight: 600;
    display: inline;
}

.header_chatscount {
    color: red;
    font-size: 14px;
    font-weight: 600;
    display: inline;
}

@media (max-width: 750px) {
    .header_icon {
        width: 30px;
        height: 30px;
    }

    .header_notificationscount {
        font-size: 12px;
    }

    .header_chatscount {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .header_icon {
        width: 30px;
        height: 30px;
    }

    .header_notificationscount {
        font-size: 10px;
    }

    .header_chatscount {
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    .header_logo {
        width: 90px;
        height: 40px;
    }

    .header_icon {
        width: 30px;
        height: 30px;
    }

    .header_notificationscount {
        font-size: 8px;
    }

    .header_chatscount {
        font-size: 8px;
    }
}