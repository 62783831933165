.adminmanagelistings {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.listingseditor_input {
    margin: auto;
    margin-top: 10px;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
    font-size: 18px;
    height: auto;
}

.adminmanagelistings_spinnercontainer {
    margin: auto;
    width: 40px;
    height: 40px;
}

.adminmanagelistings_result{
    text-align: center;
    color: red;
}

.adminmanagelistings_table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
}

.adminmanagelistings_tablerow:hover,
.adminmanagelistings_tablerow:active {
    background-color: #e3e3e3;
    cursor: pointer;
}
