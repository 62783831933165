.credits {
    margin: 0 auto;
    text-align: center;
}

.credits_currentcredits {
    margin-top: 30px;
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
}

.credits_plans {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.credits_plan {
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    padding: 20px;
    width: calc(20% - 20px);
    max-width: 300px;
    text-align: left;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.credits_plan:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.credits_plantitle {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 10px;
}

.credits_planprice {
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 15px;
}

.credits_planbutton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.credits_planbutton:hover {
    background-color: #005bb5;
}

.credits_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.credits_modalcontent {
    background: white;
    border-radius: 8px;
    padding: 25px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: relative;
}

.credits_modalheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.credits_modalheader h4 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: bold;
    color: #333;
}

.credits_modalclosebutton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #999;
}

.credits_modalclosebutton:hover {
    color: #333;
}

.credits_modalbody {
    margin-top: 20px;
}

.credits_modaltitle {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #555;
}