.admin {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.admin_item {
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
}

.admin_item:last-child {
    border-bottom: none;
}

.admin_item:hover {
    background-color: #f0f0f0;
}

@media (max-width: 600px) {
    .admin_item{
        font-size: 20px;
    }
}