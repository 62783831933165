.smartsearcheditor {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.smartsearcheditor_buttonscontainer {
    margin-top: 10px;
}

.smartsearcheditor_button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.smartsearcheditor_table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.smartsearcheditor_table th,
.smartsearcheditor_table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.smartsearcheditor_table th {
    background-color: #f2f2f2;
}

.smartsearcheditor_fullmenutabledistrict {
    background-color: #ddd;
}

.smartsearcheditor_fullmenutablecommuity {
    background-color: #f5f5f5;
}

.smartsearcheditor_fullmenutablecommunitykeywordcontainer {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}

.smartsearcheditor_fullmenutablecommunitykeywordid {
    font-weight: bold;
}

.smartsearcheditor_fullmenutablecommunitykeyword {
    font-style: italic;
}

.smartsearcheditor_tableoption {
    cursor: pointer;
    color: #007bff;
}

.smartsearcheditor_tableoption:hover {
    text-decoration: underline;
}

.smartsearcheditor_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    overflow: hidden;
}

.smartsearcheditor_modalcontent {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 90vh;
}

.smartsearcheditor_modaltitle {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.smartsearch_modalinput {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.smartsearch_modalselect {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.smartsearch_modalbutton {
    padding: 10px 20px;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    transition: background-color 0.2s;
}

.smartsearch_modalbutton:hover {
    background-color: #0056b3;
}

.smartsearch_modalbutton:active {
    background-color: #004190;
}

.smartsearch_modalbutton:last-child {
    background-color: #6c757d;
}

.smartsearch_modalbutton:last-child:hover {
    background-color: #545b62;
}

@media only screen and (max-width: 600px) {

    .smartsearcheditor_modal {
        width: 90%;
        max-width: 100%;
    }

    .smartsearcheditor_table th,
    .smartsearcheditor_table td {
        padding: 8px;
    }
}