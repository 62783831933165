.editlisting {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.editlisting_title {
    display: block;
}

.editlisting_listingid {
    margin-top:10px;
}

.editlisting_input {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: auto auto 10px;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
    font-size: 18px;
    height: auto;
}

.editlisting_textarea {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: auto auto 10px;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
    font-size: 18px;
    height: auto;
}

.editlisting_rtl {
    direction: rtl;
}

.editlisting_ltr {
    direction: ltr;
}

.editlisting_select {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: auto auto 10px;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
    font-size: 20px;
}

.editlisting_smartsearchcontainer {
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 5px;
}

.editlisting_smartsearchloadingcontainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.editlisting_smartsearchitem {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    cursor: pointer;
}

.editlisting_smartsearchitem:last-child {
    border-bottom: none;
}

.editlisting_smartsearchitem:hover {
    background-color: #f5f5f5;
}

.editlisting_resultcontainer {
    color: red;
}

.editlisting_spinnercontainer {
    margin: auto;
    width: 40px;
    height: 40px;
}

.editlisting_morelessbutton {
    font-size: 14px;
    line-height: 1.8;
    background-color: #ff0f0f;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    padding: 0.375rem 0.75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.editlisting_deletebutton {
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.editlisting_deletebutton:hover {
    background-color: #c82333;
}

.editlisting_deletebutton:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}


.editlisting_confirmationmodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 9999;
}

.editlisting_confirmationtext {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.editlisting_confirmationbutton {
    background-color: #2196F3;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    outline: none;
}

.editlisting_confirmationbutton:last-child {
    margin-right: 0;
}

@media screen and (max-width: 768px) {
    .editlisting_confirmationmodal {
        width: 80%;
        padding: 10px;
    }
}

.editlisting_submitbutton {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}

.editlisting_submitbutton:hover {
    background-color: #0069d9;
}