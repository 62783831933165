.updatepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .updatepage_title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .updatepage_paragraph {
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .updatepage_button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .updatepage_button:hover {
    background-color: #45a049;
  }