.comments {
    display: flex;
    flex-direction: column;
}

.comments_commentcontainer {
    display: block;
}

.comments_comment {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: white;
    border-radius: 5px;
    padding: 4px;
}

.comments_comment:hover {
    background-color: rgba(221, 221, 221, 0.359);
}

.comments_commentleft {
    flex: 5;
}

.comments_commentleftrow {
    display: flex;
    flex-direction: row;
}

.comments_commentleftcolleft {
    flex: 1;
}

.comments_commentavatarcontainer {
    margin-top: 3px;
}

.comments_commentavatar {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.comments_commentleftcolright {
    flex: 5;
}

.comments_commentusernamecontainer {
    text-align: left;
}

.comments_commentusername {
    cursor: pointer;
    font-weight: 700;
}

.comments_commenttextltr {
    text-align: left;
    direction: ltr;
}

.comments_commenttextrtl {
    text-align: right;
    direction: rtl;
}

.comments_commentreplycontainer {
    display: flex;
    align-items: center;
}

.comments_replybuttonsvg {
    padding: 4px;
}

.comments_replybutton {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: grey;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    margin: 0 5px !important;
    padding: 5px;
    border-radius: 4px;
}

.comments_replybutton:hover {
    outline: none;
    background-color: hsla(0, 0%, 62.7%, .315);
}

.comments_commentfromnow {
    display: inline;
    font-size: 12px;
    color: darkgrey
}

.comments_commentright {
    flex: 1;
}

.comments_commentoptionscontainer {
    position: relative;
    display: inline-block;
}

.comments_commentoptionsbutton {
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #666;
    font-size: 16px;
}

.comments_replies {
    margin-left: 35px;
    margin-top: 6px;
    margin-bottom: 6px;
    background: #ebebeb;
    border-radius: 10px;
}

.comments_reply {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 4px;
    border-radius: 5px;
}

.comments_reply:hover {
    background-color: #e2e2e293;
}

.comments_replyleft {
    flex: 5;
}

.comments_replyleftrow {
    display: flex;
    flex-direction: row;
}

.comments_replyleftcolleft {
    flex: 1;
}

.comments_replyavatarcontainer {
    margin: 3px;
}

.comments_replyavatar {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 12px;
}

.comments_replyleftcolright {
    flex: 5;
}

.comments_replyusernamecontainer {
    text-align: left;
}

.comments_replyusername {
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
}

.comments_replytextltr {
    text-align: left;
    direction: ltr;
}

.comments_replytextrtl {
    text-align: right;
    direction: rtl;
}

.comments_replyfromnow {
    font-size: 10px;
    color: darkgrey;
    text-align: left;
}

.comments_replyright {
    flex: 1;
}

.comments_replyoptionscontainer {
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #666;
    font-size: 16px;
}

.comments_repliesshowmore {
    margin-top: 10px;
    margin-bottom: 10px;
}

.comments_repliesshowmorebuttoncontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
}

.comments_repliesshowmorebutton {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.comments_repliesshowmorebutton:hover {
    background-color: #ddd;
}

.comments_commentsshowmore {
    margin: 20px 0;
}

.comments_commentsshowmorebutton {
    width: 100%;
    font-weight: bold;
    color: #333;
    border-radius: 3px;
    border: 1px solid #999;
    background-color: #f5f5f5;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.comments_loggedincontainer {
    display: flex;
    background-color: #f3f3f3;
    padding: 8px 8px 15px;
    border-radius: 8px;
    align-items: center;
    margin-bottom: 15px;
}

.comments_loggedincolumnsmall {
    flex: 1;
}

.comments_loggedinavatarcontainer {
    display: block;
}


.comments_loggedinavatar {
    width: 38px;
    height: 38px;
    border-radius: 19px;
}

.comments_loggedincolumnbig {
    flex: 5;
}

.comments_loggedininput {
    flex: 1 1 80%;
    width: 80%;
    border: none;
    border-bottom: 1px solid #181818;
    text-decoration: none;
    background-color: transparent;
    margin-left: 4px;
    padding: 1px 2px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
}

.comments_loggedininput:focus {
    outline: none;
    border-bottom: 2px solid #0e0e0e;
}

.comments_addcommentresult {
    margin-top: 5px;
    font-size: small;
    font-weight: 600;
    color: #ff2424;
}

.comments_postbutton {
    cursor: pointer;
    color: #fff;
    text-align: center;
    background-size: 200% auto;
    border-radius: 5px;
    transition: .5s;
    padding: 8px 16px;
    background-color: #84dcff;
    border: none;
    margin-right: 10px;
    font-weight: bolder;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.comments_postbutton:hover {
    transform: scale(1.1);
}

.comments_postbuttonhighlight {
    background-color: #00b7ff;
}

.comments_loggedoutcontainer {
    border: 1px solid #ddd;
    background-color: rgb(245, 245, 245);
    padding: 15px;
    margin-bottom: 15px;
}

.comments_loggedoutcolumn {
    display: block;
}

.comments_loggedouttext {
    font-weight: 500;
    color: #9c9c9c;
    font-size: 17px;
}

.comments_loginbutton {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #050505;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.comments_loginbutton:hover {
    transform: scale(1.1);
}

.comments_loginbutton {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #050505;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.comments_loginbutton:hover {
    transform: scale(1.1);
}

.comments_signupbutton {
    cursor: pointer;
    border: 2px solid #00c3ff;
    border-radius: 8px;
    background-color: #00c3ff;
    padding: 5px 10px;
    color: #fff;
    font-weight: bolder;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.comments_signupbutton:hover {
    transform: scale(1.1);
}

.comments_loggedoutcolumnbig {
    flex: 2;
}

/* Comment Options */

.commentoptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.commentoptions_button {
    margin-top: 10px;
    background-color: #84dcff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.commentoptions_button:hover {
    background-color: #00c3ff;
}

/* Edit Comment */

.editcomment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.editcomment_textarea {
    height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.editcomment_buttons {
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.editcomment_buttons:first-of-type {
    background-color: #ccc;
    color: #333;
}

.editcomment_buttons:last-of-type {
    background-color: #007bff;
    color: #fff;
}

.editcomment_resultcontainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.editcomment_spinnercontainer {
    margin-right: 10px;
}

/* Delete Confirmation */

.deleteconfirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.deleteconfirmation_title {
    font-weight: 700;
}

.deleteconfirmation_button {
    margin-top: 10px;
    background-color: #84dcff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.deleteconfirmation_button:hover {
    background-color: #00c3ff;
}

/* Reply Options */

.replyoptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.replyoptions_button {
    margin-top: 10px;
    background-color: #84dcff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.replyoptions_button:hover {
    background-color: #00c3ff;
}

/* Edit Reply */

.editreply_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.editreply_textarea {
    width: 100%;
    height: 100px;
    resize: vertical;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.editreply_buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.editreply_button {
    margin-left: 8px;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.editreply_resultcontainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.editreply_spinnercontainer {
    margin-right: 8px;
}


/* Comment Reply */

.commentreply {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.commentreply_textarea {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.commentreply_buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.commentreply_button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.commentreply_button:hover {
    background-color: #0062cc;
}

.commentreply_resultcontainer {
    display: flex;
    align-items: center;
}

.commentreply_spinnercontainer {
    margin-right: 10px;
}

/****************************************************************************************/

@media (max-width: 600px) {

    .comments_loggedinavatarcontainer,
    .comments_commentavatarcontainer,
    .comments_replyavatarcontainer {
        display: none;
    }

    .comments_loginbutton {
        font-size: 8px;
    }

    .comments_signupbutton {
        font-size: 14px;
    }

    .comments_loggedouttext,
    .comments_loggedininput,
    .comments_postbutton {
        font-size: 12px;
    }
}


@media (max-width: 400px) {

    .comments_loggedinavatarcontainer,
    .comments_commentavatarcontainer,
    .comments_replyavatarcontainer {
        display: none;
    }

    .comments_loginbutton {
        font-size: 6px;
    }

    .comments_signupbutton {
        font-size: 12px;
    }

    .comments_loggedouttext,
    .comments_loggedininput,
    .comments_postbutton {
        font-size: 10px;
    }
}