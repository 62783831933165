.agreementeditor {
    text-align: center;
    overflow-y: auto;
    height: 100vh;
}

.agreementeditor_table {
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
}

.agreementeditor_optionsbutton {
    cursor: pointer;
}

.agreementeditor_optionscontainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.agreementeditor_optionscontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.agreementeditor_optionscontent {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.agreementeditor_optiontitle {
    font-size: 18px;
    margin-bottom: 20px;
}

.agreementeditor_input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.agreementeditor_optionbutton {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.agreementeditor_optionbutton:hover {
    background-color: #d32f2f;
}