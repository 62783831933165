.navigator {
    margin: 10px 5% 0px 5%;
    padding: 5px 0px 5px 0px;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(198, 198, 198);
    font-weight: 500;
}

.navigator_link {
    cursor: pointer;
}

.navigator_link:hover {
    text-decoration: underline;
}

.navigator_seperator {
    display: inline;
}

@media (max-width: 600px) {
    .navigator {
        margin: 8px 2% 0px 2%;
        padding: 3px 0px 3px 0px;
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    .navigator {
        margin: 8px 2% 0px 2%;
        padding: 3px 0px 3px 0px;
        font-size: 8px;
    }
}