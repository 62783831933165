.mediagallery_imageContainer {
    display: inline;
    margin: 2px;
}

.mediagallery_image {
    cursor: pointer;
    border-radius: 3px;
    object-fit: cover;
}

.mediagallery_image:hover {
    filter: drop-shadow(5px 5px 5px black);
}

.mediagallery_videoContainer {
    display: block;
    width: auto;
    height: auto;
}

.mediagallery_video {
    margin: auto
}

.mediagallery_modal {
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.904);
}

.mediagallery_modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.mediagallery_modalImageContainer {
    display: block;
}

.mediagallery_modalImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

.mediagallery_rightbutton {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: rgb(212, 212, 212);
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.mediagallery_rightbutton:hover {
    color: white;
}

.mediagallery_leftbutton {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: rgb(212, 212, 212);
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.mediagallery_leftbutton:hover {
    color: white;
}

.mediagallery_options {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    width: 80%;
    color: #fff;
    background-color: #9E9E9E;
    border-radius: 4px;
}

.mediagallery_option1 {
    color: #fff;
}