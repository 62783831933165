.mylistings {
    display: block;
}

.mylistings_result {
    color: red;
}

.mylistings_searchinputgroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 20px auto;
}

.mylistings_searchinput {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.mylistings_searchinput:focus {
    border-color: #007bff;
}

.mylistings_loading {
    text-align: center;
}

.mylistings_loadingtext {
    font-size: 24px;
    font-weight: 600;
}

.mylistings_table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
}

.mylistings_table th,
.mylistings_table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.mylistings_table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.mylistings_listing:hover {
    background-color: #dcdcdc;
    cursor: pointer;
}

.mylistings_nolistings {
    text-align: center !important
}

@media (max-width: 750px) {

    .mylistings_table th,
    .mylistings_table td {
        padding: 5px;
        font-size: 14px;
    }
}

@media (max-width: 650px) {

    .mylistings_table th,
    .mylistings_table td {
        padding: 3px;
        font-size: 12px;
    }
}

@media (max-width: 530px) {

    .mylistings_table th,
    .mylistings_table td {
        padding: 2px;
        font-size: 10px;
    }
}