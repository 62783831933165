.specscomponent_table {
    padding: 5%;
}

.specscomponent_specrow {
    display: flex;
    margin: 5px;
    flex-direction: row;
    line-height: 2em;
}

.specscomponent_spec {
    flex: 1
}